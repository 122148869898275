import React from "react"
import { Link } from "gatsby"
import { Col, Container, Row } from "reactstrap"

const FooterNew = () => {
  return (
    <div className="footer-new">
      <Container>
        <Row className="justify-content-between align-items-center">
          <Col xl={5}>
            <ul className="footer-links text-center d-flex flex-wrap justify-content-center justify-content-xl-start">
              <li>
                <Link href="/technology">Information Technology</Link>
              </li>
              <li>
                <Link href="/oncology">Radiation Oncology</Link>
              </li>
            </ul>
          </Col>
          <Col xl={7} className="text-center text-xl-right">
            <span className="footer-copyright flex">
              © {new Date().getFullYear()} PeerSource. All rights reserved.
              Designed and powered by {``}
              <a
                href="https://webriq.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                WebriQ
              </a>
              .
              <br />
              <a href="/privacy/">Privacy Policy and Terms & Conditions</a>
            </span>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default FooterNew
