import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./header/header"
import HeaderNew from "./header/headerNew"
import HeaderOncology from "./header/headerOncology"
import Footer from "./footer/footer"
import FooterNew from "./footer/footerNew"

import "./layout.scss"
import "bootstrap/dist/css/bootstrap.css"
import "font-awesome/css/font-awesome.min.css"
import "./font-face.css"
import "./responsive.scss"

const Layout = ({ children, page }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  // When embedded in an iFrame, sends a postMessage "WEBRIQ_SITE_HAS_LOADED" to parent
  const [hasLoaded, setHasLoaded] = React.useState(false)
  React.useEffect(() => {
    const sendPostMessage = e => {
      if (!hasLoaded) {
        console.log("Sending postMessage: WEBRIQ_SITE_HAS_LOADED")
        window.parent.postMessage("WEBRIQ_SITE_HAS_LOADED", "*")
        console.log("Successfully sent postMessage: WEBRIQ_SITE_HAS_LOADED")
        setHasLoaded(true)
      }
    }

    sendPostMessage()
  }, [hasLoaded])

  return (
    <>
      {/* Different navigation items based on page */}
      {page === "new-home" ||
      page === "contact-us" ||
      page === "about" ||
      page === "specialties" ||
      page === "benefits" ||
      page === "news" ? (
        <HeaderNew siteTitle={data.site.siteMetadata.title} />
      ) : page === "oncology" ||
        page === "oncology-testimonials" ||
        page === "oncology-benefits" ||
        page === "oncology/contact-us" ? (
        <HeaderOncology siteTitle={data.site.siteMetadata.title} />
      ) : (
        <Header siteTitle={data.site.siteMetadata.title} page={page} />
      )}

      {/* Different footers based on page */}
      <main>{children}</main>
      {page === "new-home" ||
      page === "oncology" ||
      page === "oncology/contact-us" ? (
        <FooterNew />
      ) : (
        <Footer />
      )}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
